.bowlinewallet {
  &__modal {
    @include media-breakpoint-up(md) {
      position: fixed;
      top: 1rem;
      right: 1rem;
      margin: 0;
      min-width: 400px;
      display: flex;
      flex-direction: column; }
    @function set-color($color) {
      @if (lightness($color) < 40) {
        @return invert(1) grayscale(100%) brightness(200%); }
      @else {
        @return none; } }
    .btn-close {
      filter: set-color($dark); } } }
