$bowline-gray: #373A3F;
$bowline-green: #00c3a5;
$bowline-dark-blue: #0C0E16;

$nfg-mint: #06a38c;
$nfg-pink: #d160bc;
$nfg-bg-gradient: linear-gradient(98.77deg, #06a38c 22.2%, #d160bc 82%);

// $nfg-bg-gradient: linear-gradient(98.77deg, #8dd3c9 22.2%, #d59fcb 82%)

$letter-spacing-xs: 0.01em;
$letter-spacing-s: 0.02em;
$letter-spacing-m: 0.05em;
$letter-spacing-l: 0.1em;
$letter-spacing-xl: 0.13em;
$letter-spacing-xxl: 0.14em;

$letter-spacing-big: 0.2em;
$letter-spacing-button: 0.23em;
