.connect-modal-body {
  min-height: 65vh;
  .modal-row {
    min-height: 65vh; }
  .left-side {
    background-color: rgba($modal-content-color, .1);
    padding-top: 4.5rem; }
  .right-side {
    background-color: rgba($modal-content-color, .1);
    padding-top: 4.5rem; }
  .nav-button {
    &:hover, &.active {
      background-color: rgba($modal-content-color, .1); } } }
