// font from CDN
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700")

///mixins
@import "styles/mixins/fontface";

// fonts
// @include fontFace('rockwell', '/assets/fonts/rockwell')
@include fontFace('anton', '/assets/fonts/anton');

// theme
@import "styles/prebootstrapvariables";
@import "../node_modules/bootstrap/scss/functions";
@import "styles/bootstrapvariables";
@import "styles/variables";

// vendor modules
@import "../node_modules/bootstrap/scss/bootstrap";

// stylesheets containers
@import "styles/containers/Root";
@import "styles/containers/App";
@import "styles/containers/Landingpage";
@import "styles/containers/Dashboard";
@import "styles/containers/Account";
@import "styles/containers/FourOFour";

//stylesheets components
@import "components/About/About";
@import "components/FieldInput/FieldInput";
@import "components/FileInput/FileInput";
@import "components/LoadingContent/LoadingContent";
@import "components/Navbar/Navbar";
@import "components/Register/Register";
@import "components/LandingpageHeroImage/LandingpageHeroImage";
@import "components/OnBoardingContent/OnBoardingContent";
@import "components/BowlineWallet/BowlineWallet";
@import "components/ConnectWalletButton/AuthConnectButton";
@import "components/UnlockTokenSelector/_UnlockTokenSelector";
@import "components/ConnectWallet/ConnectWallet";
@import "components/ProjectOnboarding/ProjectOnboarding";
@import "components/Footer/Footer";

body {
  margin: 0;
  padding: 0;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.btn-circle-float {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  i {
    transform: translateY(15%); } }

.cursor-pointer {
  cursor: pointer; }

.bg-gradient {
  background: $nfg-bg-gradient !important; }

// .btn-primary
//   border-radius: $border-radius-sm !important
//   background: $nfg-bg-gradient !important
//   border: none
//   &:hover
//     opacity: .875

.font-anton {
  font-family: 'Anton', sans-serif; }

.footer, .footer .col {
  // background-color: $gray-900
  color: $white; }
